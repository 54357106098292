import { useState } from 'react';
import Checkbox from '../../Atoms/Checkbox/Checkbox';
import { styled } from '../../stitches.config';

type PropType = {
  title: string;
  selected: boolean;
  description?: string;
  onClick?: () => void;
  children?: any;
};

function SelectionModule({
  title,
  selected,
  description,
  onClick,
  children,
}: PropType) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <SelectionModuleRoot
      selected={selected}
      onClick={onClick && onClick}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <LeftSectionWrapper>
        <Checkbox
          checked={selected}
          hovered={isHovered}
          name={title}
          value={true}
          isRound
        />
      </LeftSectionWrapper>
      {children}
    </SelectionModuleRoot>
  );
}

const SelectionModuleRoot = styled('div', {
  br: 2.5,
  p: 4,
  display: 'flex',
  alignItems: 'center',
  border: '1px solid $grey100',
  '&:not(:last-child)': {
    mb: 6,
  },
  variants: {
    selected: {
      true: {
        outline: '2px solid $blue',
      },
      false: {
        '&:hover': {
          border: '1px solid $grey300',
          cursor: 'pointer',
        },
      },
    },
  },
});

const LeftSectionWrapper = styled('div', {
  display: 'flex',
  mr: 3,
});

export default SelectionModule;
