import Input from '../../../Atoms/Input/Input';
import { InputValidation } from '../../../Atoms/Input/InputValidation';
import Select from '../../../Shared/Input/Select';
import Address from '../../../Shared/Models/Address.interface';
import { useTranslationData } from '../../../Shared/Providers/TranslationProvider';
import { styled } from '../../../stitches.config';

type PropTypes = {
  onDeliveryChange: () => void;
  addressFormValue: Address;
  countries: any;
  setCustomCompany: any;
  setCustomCountryCode: any;
};

function DeliveryDetails({
  onDeliveryChange,
  addressFormValue,
  countries,
  setCustomCompany,
  setCustomCountryCode,
}: PropTypes) {
  const {
    'checkoutPage/deliveryaddress': deliveryAddressLabel,
    'customerInfo/company': companyLabel,
    'checkoutPage/zipcode': postalCodeTitleLabel,
    'validation/invalidPostalCode': invalidPostalCodeLabel,
    'checkoutPage/city': regionTitleLabel,
    'checkoutPage/careOf': careofTitleLabel,
  } = useTranslationData();

  const numberRegExp = new RegExp('(^[\\d ]*$)');
  return (
    <>
      <FormContainer>
        <InputSection>
          <InputValidation>
            <Input
              title={companyLabel}
              placeholder={companyLabel}
              onChange={(e: any) => setCustomCompany(e)}
            />
          </InputValidation>
          <InputValidation>
            <Input
              title={careofTitleLabel}
              placeholder={careofTitleLabel}
              onChange={(e: any) =>
                onDeliveryChange({ ...addressFormValue, careOf: e })
              }
            />
            <Input
              title={deliveryAddressLabel}
              placeholder={deliveryAddressLabel}
              onChange={(e: any) =>
                onDeliveryChange({ ...addressFormValue, street: e })
              }
            />
            <FlexWrapper>
              <PostNrWrapper>
                <Input
                  title={postalCodeTitleLabel}
                  placeholder={postalCodeTitleLabel}
                  validation={{
                    pattern: numberRegExp,
                    errorMessage: invalidPostalCodeLabel,
                  }}
                  onChange={(e: any) =>
                    onDeliveryChange({
                      ...addressFormValue,
                      postalCode: e,
                    })
                  }
                />
              </PostNrWrapper>
              <Input
                title={regionTitleLabel}
                placeholder={regionTitleLabel}
                onChange={(e: any) =>
                  onDeliveryChange({
                    ...addressFormValue,
                    city: e,
                  })
                }
              />
            </FlexWrapper>
          </InputValidation>
          <SelectWrapper>
            {!(countries?.length === 0) && (
              <Select
                selectedValue={addressFormValue?.country}
                onSelect={(e: any) => setCustomCountryCode(e)}
                label={'Land'}
                options={countries.map((country: any) => {
                  return {
                    text:
                      Object.keys(country)[0].charAt(0).toUpperCase() +
                      Object.keys(country)[0].slice(1),
                    value: Object.values(country)[0],
                  };
                })}
              />
            )}
          </SelectWrapper>
        </InputSection>
      </FormContainer>
    </>
  );
}

export default DeliveryDetails;

const FormContainer = styled('div', {});

const InputSection = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  mt: 3,
  mb: 8,
  '@mediaMinMedium': {
    maxW: '49%',
  },
});

const SelectWrapper = styled('div', {
  label: {
    display: 'block',
  },
  select: {
    margin: 0,
  },
});

const FlexWrapper = styled('div', {
  '@mediaMinMedium': {
    display: 'flex',
    g: 4,
  },
});

const PostNrWrapper = styled('div', {
  w: '100%',
  '@mediaMinMedium': {
    maxW: '38.5%',
  },
  '@mediaMinLarge': {
    maxW: '35%',
  },
});
