import { styled } from '../../stitches.config';

type PropType = {
  onSelect: (value: any) => void;
  selectedValue: any;
  label: string;
  options: any[];
};

const Select = ({ onSelect, selectedValue, label, options }: PropType) => {
  const handleSelect = (event: any) => onSelect(event.target.value);

  return (
    <SelectWrapper>
      <Label>{label}</Label>
      <StyledSelect value={selectedValue} onChange={handleSelect}>
        {options.map((option: any) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </StyledSelect>
    </SelectWrapper>
  );
};

const SelectWrapper = styled('div', { mb: 4 });

const Label = styled('label', {
  fs: 8,
  fontWeight: '$fw700',
  lineHeight: '28px',
});

const StyledSelect = styled('select', {
  fs: 8,
  fontWeight: '$fw700',
  backgroundColor: 'inherit',
  h: 9,
  ml: 4,
  px: 3,
  border: '1px solid $primary',
});

export default Select;
