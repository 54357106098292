import { styled } from '../../stitches.config';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { ClearCartIcons } from '../../Atoms/Icons';

function EmptyCartContainer() {
  const {
    'common/emptyCartTitle': emptyCartTitle,
    'common/emptyCartDescription': emptyCartDescription,
  } = useTranslationData();

  return (
    <>
      <Empty>
        <ClearCartIcons />
      </Empty>
      <BottomEmpty>
        <h5>{emptyCartTitle}</h5>
        <span>{emptyCartDescription}</span>
      </BottomEmpty>
    </>
  );
}

const Empty = styled('div', {
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  paddingBottom: '24px',
  mx: 4,
});

const BottomEmpty = styled('div', {
  pt: 3,
  pb: '64px',
  pl: 6,
  pr: 6,
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '1px',
  w: '100%',
  backgroundColor: '$backgroundPrimary',
  textAlign: 'center',
  h5: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '26px',
    letterSpacing: '1px',
    marginBottom: '8px',
  },
});

export default EmptyCartContainer;
