import { useEffect, useState } from 'react';
import CheckoutPageModel from '../../../CheckoutPage/Models/CheckoutPageModel.interface';
import FormSubmissionB2B from '../../../CheckoutPage/Models/FormSubmissionB2B.interface';
import { GetMyInformation } from '../../../CustomerInformation/CustomerInformationApi';
import { useKexNavigate } from '../../../Kex/KexRouter/KexRouter';
import CustomerInformationModel from '../../../MyPagesPage/Models/CustomerInformationModel';
import useCurrentPage from '../../../Shared/Hooks/useCurrentPage';
import KexInput from '../../../Shared/Input/KexInput';
import { KexInputValidation } from '../../../Shared/Input/KexInputValidation';
import { useAppSettingsData } from '../../../Shared/Providers/AppSettingsProvider';
import { useUserStateData } from '../../../Shared/UserContextProvider/UserContextProvider';
import { styled } from '../../../stitches.config';
import { GetCart } from '../../CartPage/Cart';
import { PlaceB2BOrder } from '../CheckoutApi';
import CheckoutProductListing, {
  CheckoutSectionTitle,
} from '../CheckoutProductListing';
import KexDeliveryOptionModel from '../../../CheckoutPage/Models/KexDeliveryOptionModel.interface';
import KexPaymentOptionModel from '../../../CheckoutPage/Models/KexPaymentOptionModel.interface';
import { CheckoutSectionContainer } from '../B2C/CheckoutB2CPage';
import CtaButton from '../../../Atoms/Buttons/CtaButton';
import { useTranslationData } from '../../../Shared/Providers/TranslationProvider';
import { useHeaderData } from '../../../Organisms/Header/Header';
import BillingInformation from './BillingInformation';
import DeliverySelection from './DeliverySelection';
import Input from '../../../Atoms/Input/Input';
import { InputValidation } from '../../../Atoms/Input/InputValidation';
import EmptyCartContainer from '../../../Organisms/EmptyCartContainer/EmptyCartContainer';

function CheckoutB2BPage() {
  const {
    languageRoute,
    staticPages: { orderConfirmationPage, loginPage },
  } = useAppSettingsData();

  const {
    'checkoutPage/order/yourOrder': yourOrderLabel,
    'checkoutPage/order/message': messageLabel,
    'checkoutPage/order/referenceNumber': referenceNumberLabel,
    'checkoutPage/order/additionalInformation': additionalInformationLabel,
    'checkoutPage/order/sendOrder': sendOrderLabel,
    'common/emptyCart': emptyCartLabel,
    'common/errorMessage': errorMessageLabel,
  } = useTranslationData();

  const kexNavigate = useKexNavigate();
  const { channelId, pageHeading, countries } =
    useCurrentPage<CheckoutPageModel>();
  const { cart } = GetCart();
  const { company, firstName, lastName, email, phoneNumber, customerId } =
    useUserStateData();
  const [messageText, setMessageText] = useState<string>('');
  const [customerReferenceId, setCustomerReferenceId] = useState<string>('');
  const [submitErrorMsg, setSubmitErrorMsg] = useState<string>('');
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);

  const customerInfoUrl = `/api/${
    languageRoute || 'en'
  }/customer/GetMyInformation?customerId=${customerId}`;
  const [customerInformation, setCustomerInformation] =
    useState<CustomerInformationModel>();

  const [user] = useState<UserState>(useUserStateData());
  const { dontAllowLogin } = useHeaderData();
  const deliveryAddress = customerInformation?.deliveryAddress
    ? customerInformation?.deliveryAddress
    : customerInformation?.optionalDeliveryAddresses[0];
  const optionalDeliveryAddresses =
    customerInformation?.optionalDeliveryAddresses;

  const [addressFormValue, setAddressFormValue] = useState(deliveryAddress);

  const [customCompany, setCustomCompany] = useState();
  const [customCountryCode, setCustomCountryCode] = useState(
    countries
      .reduce((pairs, obj) => [...pairs, Object.entries(obj)], [])
      .flat()[0][1]
  );

  useEffect(() => {
    if (customerId) {
      GetMyInformation(channelId, customerInfoUrl).then(
        (data: any) => data && setCustomerInformation(data)
      );
    }
  }, [channelId]);

  const selectedDeliveryOption: KexDeliveryOptionModel = {
    //hardcoded for now
    providerOptionId: {
      optionId: 'standardPackage',
      providerId: 'DirectShipment',
    },
  };

  const selectedPaymentOption: KexPaymentOptionModel = {
    //hardcoded for now
    providerOptionId: {
      optionId: 'DirectPay',
      providerId: 'DirectPayment',
    },
  };

  if (!user.authenticated) {
    kexNavigate(loginPage);
  }

  const orderSubmitData: FormSubmissionB2B = {
    company: company,
    customCompany: customCompany,
    customCountryCode: customCountryCode,
    customerId: customerId,
    firstName: firstName,
    lastName: lastName,
    email: email,
    phoneNumber: phoneNumber,
    billingAddress:
      customerInformation &&
      customerInformation.invoiceAddress &&
      customerInformation.invoiceAddress.street,
    billingCity:
      customerInformation &&
      customerInformation.invoiceAddress &&
      customerInformation.invoiceAddress.city,
    billingCountry:
      customerInformation &&
      customerInformation.invoiceAddress &&
      customerInformation.invoiceAddress.country,
    billingPostalCode:
      customerInformation &&
      customerInformation.invoiceAddress &&
      customerInformation.invoiceAddress.postalCode,
    shippingCareOf: addressFormValue
      ? addressFormValue.careOf
      : customerInformation &&
        customerInformation.deliveryAddress &&
        customerInformation.deliveryAddress.careOf,
    shippingAddress: addressFormValue
      ? addressFormValue.street
      : customerInformation &&
        customerInformation.deliveryAddress &&
        customerInformation.deliveryAddress.street,
    shippingCity: addressFormValue
      ? addressFormValue.city
      : customerInformation &&
        customerInformation.deliveryAddress &&
        customerInformation.deliveryAddress.city,
    shippingCountry: addressFormValue
      ? addressFormValue.country
      : customerInformation &&
        customerInformation.deliveryAddress &&
        customerInformation.deliveryAddress.country,
    shippingPostalCode: addressFormValue
      ? addressFormValue.postalCode
      : customerInformation &&
        customerInformation.deliveryAddress &&
        customerInformation.deliveryAddress.postalCode,
    orderNote: messageText,
    selectedDeliveryMethod: selectedDeliveryOption,
    selectedPaymentMethod: selectedPaymentOption,
    customersReferenceId: customerReferenceId,
  };

  useEffect(() => {
    const data = addressFormValue;
    orderSubmitData.shippingAddress = data?.street
      ? data.street
      : orderSubmitData.shippingAddress;
    orderSubmitData.shippingCity = data?.city
      ? data.city
      : orderSubmitData.shippingCity;
    orderSubmitData.shippingCountry = data?.country
      ? data.country
      : orderSubmitData.shippingCountry;
    orderSubmitData.shippingPostalCode = data?.postalCode
      ? data.postalCode
      : orderSubmitData.shippingPostalCode;
  });

  async function submitOrder() {
    const data = await PlaceB2BOrder(
      orderSubmitData,
      channelId,
      languageRoute,
      setSubmitIsLoading
    );
    if (data.success) {
      let url = `${orderConfirmationPage}?orderId=${data.orderId}`;
      kexNavigate(url);
    } else {
      setSubmitErrorMsg(errorMessageLabel);
    }
  }

  if (dontAllowLogin) {
    kexNavigate(`/${languageRoute}/`);
  }

  return (
    <main>
      <PageWrapper>
        {cart && cart.lineItems && !!cart.lineItems.length ? (
          <>
            <CheckoutSectionContainer>
              <CheckoutProductListing cart={cart} heading={pageHeading} />
            </CheckoutSectionContainer>
            {customerInformation && (
              <CheckoutSectionContainer>
                <BillingInformation customerInformation={customerInformation} />
              </CheckoutSectionContainer>
            )}
            {deliveryAddress && (
              <CheckoutSectionContainer>
                <DeliverySelection
                  companyName={customerInformation?.companyName}
                  defaultDeliveryAddress={deliveryAddress}
                  addressFormValue={addressFormValue}
                  onDeliveryChange={setAddressFormValue}
                  optionalDeliveryAddresses={optionalDeliveryAddresses}
                  setCustomCompany={setCustomCompany}
                  setCustomCountryCode={setCustomCountryCode}
                  countries={countries}
                />
              </CheckoutSectionContainer>
            )}
            <CheckoutSectionContainer>
              <CheckoutSectionTitle>{yourOrderLabel}</CheckoutSectionTitle>
              <OrderContentWrapper>
                <InputValidation>
                  <Input
                    onChange={setCustomerReferenceId}
                    title={''}
                    placeholder={referenceNumberLabel}
                    round={true}
                  />
                </InputValidation>
                <KexInputValidation>
                  <KexInput
                    onChange={setMessageText}
                    type="textarea"
                    placeholder={additionalInformationLabel}
                  />
                </KexInputValidation>
                <OrderSummaryContainer>
                  <ButtonWrapper>
                    <CtaButton
                      onClick={() => submitOrder()}
                      isLoading={submitIsLoading}
                      type="secondary"
                      uppercase
                    >
                      {sendOrderLabel}
                    </CtaButton>
                  </ButtonWrapper>
                  {submitErrorMsg && (
                    <ErrorMessage>{submitErrorMsg}</ErrorMessage>
                  )}
                </OrderSummaryContainer>
              </OrderContentWrapper>
            </CheckoutSectionContainer>
          </>
        ) : !(Object.keys(cart).length === 0) ? (
          <CheckoutSectionContainer>
            <CheckoutSectionTitle>
              <EmptyCartContainer />
            </CheckoutSectionTitle>
          </CheckoutSectionContainer>
        ) : (
          <></>
        )}
      </PageWrapper>
    </main>
  );
}

export default CheckoutB2BPage;

const OrderContentWrapper = styled('div', {
  maxW: 125,
  mx: 'auto',
  '@mediaMinLarge': {
    maxW: '100%',
    mt: 6,
  },
});

const ErrorMessage = styled('div', { color: '$errorPrimary' });

const ButtonWrapper = styled('div', {
  '@mediaMinLarge': {
    w: 'fit-content',
    m: 'auto',
  },
});

const StyledKexInput = styled(KexInput, {
  border: '1px solid red',
});

const PageWrapper = styled('div', {
  '@mediaMinVerySmall': {
    px: 4,
  },
  '@mediaMinLarge': {
    maxW: 225,
    mx: 'auto',
    px: 0,
    py: 8,
  },
});

const OrderSummaryContainer = styled('div', {
  mt: 10,
  pb: 8,
  '@mediaMinLarge': {
    mt: 16,
    pb: 0,
  },
});
