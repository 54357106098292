import Checkbox from '../../../Atoms/Checkbox/Checkbox';
import KexCart from '../../../Cart/Models/KexCart.interface';
import SelectionModule from '../../../Molecules/SelectionModule/SelectionModule';
import { styled } from '../../../stitches.config';
import CheckoutB2CPageModel from '../../../CheckoutPage/Models/CheckoutB2CPageModel.interface';
import useCurrentPage from '../../../Shared/Hooks/useCurrentPage';
import { CheckoutSectionTitle } from '../CheckoutProductListing';
import KexPaymentOptionModel from '../../../CheckoutPage/Models/KexPaymentOptionModel.interface';
import { useState } from 'react';
import CtaButton from '../../../Atoms/Buttons/CtaButton';
import CheckoutOrderSummary from '../CheckoutOrderSummary';
import { useTranslationData } from '../../../Shared/Providers/TranslationProvider';

type PropType = {
  cart: KexCart;
  availablePaymentOptions: KexPaymentOptionModel[];
  onOrderSubmit: () => void;
  submitIsLoading?: boolean;
  errorMessage?: string;
};

function CompletePurchase({
  cart,
  availablePaymentOptions,
  onOrderSubmit,
  submitIsLoading,
  errorMessage,
}: PropType) {
  const {
    'checkoutPage/completePurchase': completePurchaseLabel,
    'checkoutPage/paymentMethods': paymentMethodsLabel,
    'checkoutPage/order/sendOrder': sendOrderLabel,
  } = useTranslationData();

  const { termsAndConditionsText } = useCurrentPage<CheckoutB2CPageModel>();

  const [selectedDeliveryMethod, setSelectedDeliveryMethod] =
    useState<KexPaymentOptionModel>(availablePaymentOptions[0]);

  return (
    <div>
      <CheckoutSectionTitle>{completePurchaseLabel}</CheckoutSectionTitle>
      <CheckoutOrderSummary cart={cart} />
      <TermsWrapper>
        <Checkbox name="name" value={true}>
          <TermsText
            dangerouslySetInnerHTML={{
              __html: termsAndConditionsText,
            }}
          ></TermsText>
        </Checkbox>
      </TermsWrapper>
      <CheckoutSectionTitle>{paymentMethodsLabel}</CheckoutSectionTitle>
      <SelectionContainer>
        {availablePaymentOptions.map((paymentOption: KexPaymentOptionModel) => (
          <SelectionModule
            key={paymentOption.name}
            title={paymentOption.name}
            description={paymentOption.description}
            selected={
              availablePaymentOptions.length === 1
                ? true
                : selectedDeliveryMethod.name === paymentOption.name
            }
            onClick={() => setSelectedDeliveryMethod(paymentOption)}
          ></SelectionModule>
        ))}
      </SelectionContainer>
      <CtaButtonWrapper>
        <CtaButton onClick={onOrderSubmit} isLoading={submitIsLoading}>
          {sendOrderLabel}
        </CtaButton>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </CtaButtonWrapper>
    </div>
  );
}

export default CompletePurchase;

const TermsText = styled('div', {
  display: 'flex',
  fs: 6,
});

const SelectionContainer = styled('div', {
  mb: 8,
});

const TermsWrapper = styled('div', {
  pb: 4,
});

const CtaButtonWrapper = styled('div', {
  mx: 'auto',
  '@mediaMinMedium': {
    maxW: '50%',
  },
});

const ErrorMessage = styled('div', { color: '$errorPrimary' });
