import KexInput from '../../../Shared/Input/KexInput';
import { KexInputValidation } from '../../../Shared/Input/KexInputValidation';
import { useTranslationData } from '../../../Shared/Providers/TranslationProvider';
import { styled } from '../../../stitches.config';
import { CheckoutSectionTitle } from '../CheckoutProductListing';

type PropTypes = {
  formData: any;
  setFormData?: any;
};

function DeliveryDetails({ setFormData, formData }: PropTypes) {
  const {
    'checkoutPage/deliveryaddress': deliveryAddressLabel,
    'checkoutPage/contactInformationLabel': contactInformationLabel,
    'common/yourInformation': yourInformationLabel,
    'input/firstNameTitle': firstNameTitleLabel,
    'input/firstNamePlaceholder': firstNamePlaceholder,
    'input/lastNameTitle': lastNameTitleLabel,
    'input/lastNamePlaceholder': lastNamePlaceholderLabel,
    'input/addressTitle': addressTitleLabel,
    'input/addressPlaceholder': addressPlaceholderLabel,
    'input/postalCodeTitle': postalCodeTitleLabel,
    'input/postalCodePlaceholder': postalCodePlaceholderLabel,
    'validation/invalidPostalCode': invalidPostalCodeLabel,
    'input/regionTitle': regionTitleLabel,
    'input/regionPlaceholder': regionPlaceholderLabel,
    'input/phoneNumberTitle': phoneNumberTitleLabel,
    'input/phoneNrPlaceholder': phoneNumberPlaceholderLabel,
    'validation/invalidPhoneNumber': invalidPhoneNrLabel,
    'input/emailTitle': emailTitleLabel,
    'input/emailPlaceholder': emailPlaceholderLabel,
    'validation/invalidEmail': invalidEmailLabel,
  } = useTranslationData();

  const phoneRegExp = new RegExp('(^[\\s-+()0-9]{6,16}$)');
  const numberRegExp = new RegExp('(^[\\d ]*$)');
  const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  return (
    <>
      <CheckoutSectionTitle>{deliveryAddressLabel} </CheckoutSectionTitle>
      <FormContainer>
        <InputSectionTitle>{yourInformationLabel}</InputSectionTitle>
        <InputSection>
          <KexInputValidation>
            <FlexWrapper>
              <StyledKexInput
                title={firstNameTitleLabel}
                placeholder={firstNamePlaceholder}
                onChange={(e: any) =>
                  setFormData({ ...formData, firstName: e })
                }
              />
              <StyledKexInput
                title={lastNameTitleLabel}
                placeholder={lastNamePlaceholderLabel}
                onChange={(e: any) => setFormData({ ...formData, lastName: e })}
              />
            </FlexWrapper>
          </KexInputValidation>
        </InputSection>
        <BottomSection>
          <InputSectionTitle>{deliveryAddressLabel}</InputSectionTitle>
          <InputSection>
            <KexInputValidation>
              <StyledKexInput
                title={addressTitleLabel}
                placeholder={addressPlaceholderLabel}
                onChange={(e: any) =>
                  setFormData({ ...formData, shippingAddress: e })
                }
              />
              <FlexWrapper>
                <PostNrWrapper>
                  <StyledKexInput
                    title={postalCodeTitleLabel}
                    placeholder={postalCodePlaceholderLabel}
                    validation={{
                      pattern: numberRegExp,
                      errorMessage: invalidPostalCodeLabel,
                    }}
                    onChange={(e: any) =>
                      setFormData({ ...formData, shippingPostalCode: e })
                    }
                  />
                </PostNrWrapper>
                <StyledKexInput
                  title={regionTitleLabel}
                  placeholder={regionPlaceholderLabel}
                  onChange={(e: any) =>
                    setFormData({ ...formData, shippingDistrict: e })
                  }
                />
              </FlexWrapper>
            </KexInputValidation>
          </InputSection>
          <InputSectionTitle>{contactInformationLabel}</InputSectionTitle>
          <InputSection>
            <KexInputValidation>
              <StyledKexInput
                title={phoneNumberTitleLabel}
                placeholder={phoneNumberPlaceholderLabel}
                validation={{
                  pattern: phoneRegExp,
                  errorMessage: invalidPhoneNrLabel,
                }}
                onChange={(e: any) =>
                  setFormData({ ...formData, phoneNumber: e })
                }
              />
              <StyledKexInput
                title={emailTitleLabel}
                type={'email'}
                placeholder={emailPlaceholderLabel}
                validation={{
                  pattern: emailRegExp,
                  errorMessage: invalidEmailLabel,
                }}
                onChange={(e: any) => setFormData({ ...formData, email: e })}
              />
            </KexInputValidation>
          </InputSection>
        </BottomSection>
      </FormContainer>
    </>
  );
}

export default DeliveryDetails;

const FormContainer = styled('div', {});

const InputSection = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  mt: 3,
  mb: 8,
});

const BottomSection = styled('div', {
  '@mediaMinMedium': {
    maxW: '49%',
  },
});

const InputSectionTitle = styled('span', {
  fs: 7,
  fontWeight: '$fw700',
});

const FlexWrapper = styled('div', {
  '@mediaMinMedium': {
    display: 'flex',
    g: 4,
  },
});

const StyledKexInput = styled(KexInput, {
  w: '100%',
  backgroundColor: '$white',
});

const PostNrWrapper = styled('div', {
  w: '100%',
  '@mediaMinMedium': {
    maxW: '38.5%',
  },
  '@mediaMinLarge': {
    maxW: '35%',
  },
});
