import { useState } from 'react';
import Checkbox from '../../../Atoms/Checkbox/Checkbox';
import SelectionModule from '../../../Molecules/SelectionModule/SelectionModule';
import Address from '../../../MyPagesPage/Models/Address.interface';
import { useTranslationData } from '../../../Shared/Providers/TranslationProvider';
import { styled } from '../../../stitches.config';
import { CheckoutSectionTitle } from '../CheckoutProductListing';
import DeliveryDetails from './DeliveryDetails';

type PropTypes = {
  companyName?: string;
  defaultDeliveryAddress: Address;
  addressFormValue?: Address;
  onDeliveryChange: (address: Address) => void;
  optionalDeliveryAddresses?: Address[];
  countries: any;
  setCustomCompany: any;
  setCustomCountryCode: any;
};

function DeliverySelection({
  companyName,
  defaultDeliveryAddress,
  addressFormValue,
  onDeliveryChange,
  optionalDeliveryAddresses,
  countries,
  setCustomCompany,
  setCustomCountryCode,
}: PropTypes) {
  const {
    'checkoutPage/deliveryaddress': deliveryAddressHeading,
    'checkoutPage/careOf': careOfLabel,
    'checkoutPage/customDeliveryAddress': customAddressLabel,
  } = useTranslationData();

  const [selectedDeliveryAddress, setSelectedDeliveryAddress] =
    useState<Address>(defaultDeliveryAddress);

  const [customDeliveryAddress, setCustomDeliveryAddress] =
    useState<boolean>(false);

  const onChange = (address: Address) => {
    onDeliveryChange(address);
    setSelectedDeliveryAddress(address);
  };

  const renderDeliveryOptionContent = (
    streetAddress: string,
    careOf: string,
    postalCode: string,
    city: string
  ) => {
    return (
      <Container>
        <Bold>{companyName}</Bold>
        {careOf && (
          <span>
            {careOfLabel} {careOf}
          </span>
        )}
        <span>{streetAddress}</span>
        <span>
          {postalCode} {city}
        </span>
      </Container>
    );
  };

  return (
    <>
      <CheckoutSectionTitle>{deliveryAddressHeading}</CheckoutSectionTitle>
      <DeliveryOptionsWrapper>
        <SelectionModule
          title={defaultDeliveryAddress.id}
          selected={selectedDeliveryAddress.id === defaultDeliveryAddress.id}
          onClick={() => onChange(defaultDeliveryAddress)}
        >
          {renderDeliveryOptionContent(
            defaultDeliveryAddress.street,
            defaultDeliveryAddress.careOf,
            defaultDeliveryAddress.postalCode,
            defaultDeliveryAddress.city
          )}
        </SelectionModule>
        {optionalDeliveryAddresses &&
          optionalDeliveryAddresses.map(
            (optionalDeliveryAddress: Address) =>
              optionalDeliveryAddress.id !== defaultDeliveryAddress.id && (
                <SelectionModule
                  key={optionalDeliveryAddress.id}
                  title={optionalDeliveryAddress.id}
                  selected={
                    selectedDeliveryAddress.id === optionalDeliveryAddress.id
                  }
                  onClick={() => onChange(optionalDeliveryAddress)}
                >
                  {renderDeliveryOptionContent(
                    optionalDeliveryAddress.street,
                    optionalDeliveryAddress.careOf,
                    optionalDeliveryAddress.postalCode,
                    optionalDeliveryAddress.city
                  )}
                </SelectionModule>
              )
          )}

        <CustomDeliveryAddress>
          <Checkbox
            name="custom-delivery-address"
            value={true}
            filterType={'text'}
            onChange={(value, checked, filterType) => {
              setCustomDeliveryAddress(!customDeliveryAddress);
            }}
          >
            <span>{customAddressLabel}</span>
          </Checkbox>

          {customDeliveryAddress && (
            <DeliveryDetails
              addressFormValue={addressFormValue}
              onDeliveryChange={onDeliveryChange}
              countries={countries}
              setCustomCompany={setCustomCompany}
              setCustomCountryCode={setCustomCountryCode}
            />
          )}
        </CustomDeliveryAddress>
      </DeliveryOptionsWrapper>
    </>
  );
}

export default DeliverySelection;

const CustomDeliveryAddress = styled('div', {
  mt: '50px',
});

const DeliveryOptionsWrapper = styled('div', {
  mt: 6,
  display: 'flex',
  flexDirection: 'column',
});

const Bold = styled('span', {
  fontWeight: '$fw700',
});

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  fs: 5,
  lineHeight: '16px',
  rowGap: '4px',
  '@mediaMinLarge': {
    fs: 7,
  },
});
