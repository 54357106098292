import { useState } from 'react';
import KexDeliveryOptionModel from '../../../CheckoutPage/Models/KexDeliveryOptionModel.interface';
import SelectionModule from '../../../Molecules/SelectionModule/SelectionModule';
import { useTranslationData } from '../../../Shared/Providers/TranslationProvider';
import { styled } from '../../../stitches.config';
import { CheckoutSectionTitle } from '../CheckoutProductListing';

type PropType = {
  deliveryOptions: KexDeliveryOptionModel[];
  onChange: (value: KexDeliveryOptionModel) => void;
};

function DeliveryOptions({ deliveryOptions, onChange }: PropType) {
  const { 'checkoutPage/deliveryOptions': deliveryOptionsLabel } =
    useTranslationData();

  const [selectedDeliveryMethod, setSelectedDeliveryMethod] =
    useState<KexDeliveryOptionModel>(deliveryOptions[0]);

  const onDeliveryChange = (deliveryOption: KexDeliveryOptionModel) => {
    setSelectedDeliveryMethod(deliveryOption);
    onChange(deliveryOption);
  };

  return (
    <div>
      <CheckoutSectionTitle>{deliveryOptionsLabel}</CheckoutSectionTitle>
      <SelectionContainer>
        {deliveryOptions.map((deliveryOption: KexDeliveryOptionModel) => (
          <SelectionModule
            key={deliveryOption.name}
            title={deliveryOption.name}
            description={deliveryOption.description}
            selected={
              deliveryOptions.length === 1
                ? true
                : selectedDeliveryMethod.name === deliveryOption.name
            }
            onClick={() => onDeliveryChange(deliveryOption)}
          >
            <span>{deliveryOption.formattedPrice}</span>
          </SelectionModule>
        ))}
      </SelectionContainer>
    </div>
  );
}

const SelectionContainer = styled('div', {
  pt: 2,
});

export default DeliveryOptions;
