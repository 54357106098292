import { useState } from 'react';
import CheckoutB2CPageModel from '../../../CheckoutPage/Models/CheckoutB2CPageModel.interface';
import FormSubmissionB2C from '../../../CheckoutPage/Models/FormSubmissionB2C.interface';
import KexDeliveryOptionModel from '../../../CheckoutPage/Models/KexDeliveryOptionModel.interface';
import { useKexNavigate } from '../../../Kex/KexRouter/KexRouter';
import useCurrentPage from '../../../Shared/Hooks/useCurrentPage';
import { useAppSettingsData } from '../../../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../../../Shared/Providers/TranslationProvider';
import { styled } from '../../../stitches.config';
import { GetCart } from '../../CartPage/Cart';
import { PlaceB2COrder } from '../CheckoutApi';
import CheckoutProductListing, {
  CheckoutSectionTitle,
} from '../CheckoutProductListing';
import CompletePurchase from './CompletePurchase';
import DeliveryDetails from './DeliveryDetails';
import DeliveryOptions from './DeliveryOptions';

function CheckoutB2CPage() {
  const {
    languageRoute,
    staticPages: { orderConfirmationPage },
  } = useAppSettingsData();

  const {
    'common/emptyCart': emptyCartLabel,
    'common/errorMessage': errorMessageLabel,
  } = useTranslationData();

  const kexNavigate = useKexNavigate();
  const { channelId, availableDeliveryOptions, availablePaymentOptions } =
    useCurrentPage<CheckoutB2CPageModel>();
  const { cart } = GetCart();
  const [submitErrorMsg, setSubmitErrorMsg] = useState<string>('');
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);

  const intialFormData: FormSubmissionB2C = {
    selectedDeliveryMethod: availableDeliveryOptions[0],
    selectedPaymentMethod: availablePaymentOptions[0],
  };

  const [b2cOrderSubmitData, setB2cOrderSubmitData] =
    useState<FormSubmissionB2C>(intialFormData);

  const onDeliveryChange = (selectedDelivery: KexDeliveryOptionModel) => {
    setB2cOrderSubmitData({
      ...b2cOrderSubmitData,
      selectedDeliveryMethod: selectedDelivery,
    });
  };

  function submitOrder() {
    PlaceB2COrder(
      b2cOrderSubmitData,
      channelId,
      languageRoute,
      setSubmitIsLoading
    ).then((data) => {
      setSubmitIsLoading(false);
      if (data.success) {
        let url = `${orderConfirmationPage}?orderId=${data.orderId}`;
        kexNavigate(url);
      } else {
        setSubmitErrorMsg(errorMessageLabel);
      }
    });
  }

  return (
    <main>
      <PageWrapper>
        {cart && cart.lineItems && !!cart.lineItems.length ? (
          <>
            <CheckoutSectionContainer>
              <CheckoutProductListing cart={cart} />
            </CheckoutSectionContainer>
            <CheckoutSectionContainer>
              <DeliveryDetails
                formData={b2cOrderSubmitData}
                setFormData={setB2cOrderSubmitData}
              />
            </CheckoutSectionContainer>
            {availableDeliveryOptions && !!availableDeliveryOptions.length && (
              <CheckoutSectionContainer>
                <DeliveryOptions
                  deliveryOptions={availableDeliveryOptions}
                  onChange={onDeliveryChange}
                />
              </CheckoutSectionContainer>
            )}
            {availablePaymentOptions && !!availablePaymentOptions.length && (
              <CheckoutSectionContainer>
                <CompletePurchase
                  cart={cart}
                  availablePaymentOptions={availablePaymentOptions}
                  onOrderSubmit={submitOrder}
                  submitIsLoading={submitIsLoading}
                  errorMessage={submitErrorMsg}
                />
              </CheckoutSectionContainer>
            )}
          </>
        ) : !(Object.keys(cart).length === 0) ? (
          <CheckoutSectionContainer>
            <CheckoutSectionTitle>{emptyCartLabel}</CheckoutSectionTitle>
          </CheckoutSectionContainer>
        ) : (
          <></>
        )}
      </PageWrapper>
    </main>
  );
}

export default CheckoutB2CPage;

const PageWrapper = styled('div', {
  p: 4,
  '@mediaMinLarge': {
    maxWidth: '864px',
    mx: 'auto',
    px: 0,
    pt: 8,
  },
});

export const CheckoutSectionContainer = styled('div', {
  backgroundColor: '$white',
  px: 4,
  py: 8,
  mb: 4,
  '@mediaMinVerySmall': {
    br: 2.5,
  },
  '@mediaMinLarge': {
    py: 16,
    px: 25.5,
    mb: 8,
  },
});
