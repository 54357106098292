import CustomerInformationModel from '../MyPagesPage/Models/CustomerInformationModel';
import Fetcher from '../Shared/Common/Fetcher';

let abortController: AbortController = new AbortController();

export async function GetMyInformation(channelId: string, url: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;

  const litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });

  return Fetcher<any, CustomerInformationModel>(
    url,
    signal,
    (data, resolve) => {
      resolve(data);
    },
    litiumContext
  );
}

export async function UpdateMyInformation(
  channelId: string,
  requestToken: string,
  data: any,
  url: any
) {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'litium-request-context': JSON.stringify({
        channelSystemId: channelId,
      }),
      RequestVerificationToken: requestToken,
    },
    body: JSON.stringify(data),
  });
  const responseJson = await res.json();

  return {
    status: res.status,
    ...responseJson,
  };
}
