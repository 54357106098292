import KexCart from '../../Cart/Models/KexCart.interface';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { styled } from '../../stitches.config';

type PropType = {
  cart: KexCart;
};

function CheckoutOrderSummary({ cart }: PropType) {
  const { 'common/discount': discountLabel } = useTranslationData();

  return (
    <OrderSummaryContainer>
      <OrderSummaryWrapper>
        <Attributes>
          <span>Items:</span>
          <span>Sub total inc. tax:</span>
          <span>Shipping:</span>
          {cart.totalDiscount && <span>{discountLabel}:</span>}
          <span>Order total inc. tax:</span>
        </Attributes>
        <Values>
          <span>{cart.numberOfItems}</span>
          <span>{cart.totalPriceForItems}</span>
          <span>{cart.totalFreightPrice}</span>
          {cart.totalDiscount && <Discount>{cart.totalDiscount}</Discount>}
          <span>{cart.totalPrice}</span>
        </Values>
      </OrderSummaryWrapper>
    </OrderSummaryContainer>
  );
}

export default CheckoutOrderSummary;

const OrderSummaryContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  w: '100%',
  mb: 4,
  '@mediaMinLarge': {
    mb: 6,
  },
});

const OrderSummaryWrapper = styled('div', {
  display: 'flex',
  fs: 8,
  lineHeight: '28px',
});

const Attributes = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  mr: 2,
  textAlign: 'right',
});

const Discount = styled('span', {
  color: '$errorText',
});

const Values = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  fontWeight: '$fw700',
  opacity: 0.9,
});
