import { useEffect, useState } from 'react';
import { styled } from '../../stitches.config';

type PropType = {
  name: string;
  value: boolean | string;
  label?: string;
  checked?: boolean;
  hovered?: boolean;
  onChange?: (value: string, checked: boolean, filterType: string) => void;
  filterType?: string;
  isRound?: boolean;
  children?: any;
};

function Checkbox({
  name,
  value,
  label,
  checked = false,
  hovered,
  onChange,
  filterType,
  isRound = false,
  children,
}: PropType) {
  const [isChecked, setIsChecked] = useState(checked);
  const [isHovered, setIsHovered] = useState(hovered);

  const onInputChange = (checked: boolean, val: string) => {
    setIsChecked(checked);
    onChange && filterType && onChange(val, checked, filterType);
  };

  useEffect(() => {
    setIsChecked(checked);
    setIsHovered(hovered);
  }, [checked, hovered]);

  return (
    <Root
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <LabelContainer>
        <Label htmlFor={name + value}>
          <LabelInner>
            <Input
              checked={isChecked}
              id={name + value}
              name={name}
              value={encodeURIComponent(value)}
              type="checkbox"
              onChange={(e: any) => onInputChange(e.target.checked, name)}
            />
            <Square
              isChecked={isChecked}
              isRound={isRound}
              isHovered={isHovered}
              id="square"
            ></Square>
            {label ? <FilterText>{label}</FilterText> : children}
          </LabelInner>
        </Label>
      </LabelContainer>
    </Root>
  );
}

const Root = styled('div', {
  display: 'flex',
  position: 'relative',
  w: 'fit-content',
});

const LabelContainer = styled('div', {
  display: 'flex',
  whiteSpace: 'pre-wrap',
  w: '100%',
});

const LabelInner = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
});

const Input = styled('input', {
  opacity: 0,
  position: 'absolute',
  cursor: 'pointer',
});

const FilterText = styled('span', {});

const Square = styled('div', {
  position: 'relative',
  mr: '16px',
  l: 0,
  wh: 4,
  br: '2px',
  backgroundColor: '$white',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  p: '3px',
  transition: '$300 all linear',
  border: '2px solid $primary',
  variants: {
    isChecked: {
      true: {
        backgroundColor: '$primary',
        backgroundClip: 'content-box',
        '&:after': {
          transform: 'scale(1)',
        },
      },
    },
    isRound: {
      true: {
        '&:after': {
          br: 1.5,
        },
        br: 2,
      },
    },
    isHovered: {
      true: {
        backgroundColor: '$grey100',
      },
    },
  },
  '&:after': {
    w: '24px',
    h: '100%',
    content: '" "',
    display: 'block',
    position: 'relative',
    backgroundColor: '$primary',
    transform: 'scale(0)',
    transition: '$200 transform linear',
  },
});

const Label = styled('label', {
  display: 'flex',
  justifyContent: 'space-between',
  w: '100%',
  fontSize: '14px',
  fontWeight: '$fw400',
  lineHeight: '24px',
  letterSpacing: '1px',
  cursor: 'pointer',
});

export default Checkbox;
