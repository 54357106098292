import CustomerInformationModel from '../../../MyPagesPage/Models/CustomerInformationModel';
import { useTranslationData } from '../../../Shared/Providers/TranslationProvider';
import { styled } from '../../../stitches.config';
import { CheckoutSectionTitle } from '../CheckoutProductListing';

type PropType = {
  customerInformation: CustomerInformationModel;
};

function BillingInformation({ customerInformation }: PropType) {
  const {
    'checkoutPage/billingHeading': billingHeading,
    'checkoutPage/orderReference': orderReferenceLabel,
  } = useTranslationData();

  return (
    <Container>
      <CheckoutSectionTitle>{billingHeading}</CheckoutSectionTitle>
      <Wrapper>
        <InfoSection>
          <Bold>{customerInformation.companyName}</Bold>
          <span>{customerInformation.invoiceAddress.street}</span>
          <span>
            {customerInformation.invoiceAddress.postalCode}{' '}
            {customerInformation.invoiceAddress.city}
          </span>
        </InfoSection>
        <InfoSection>
          <Bold>{orderReferenceLabel}</Bold>
          <span>
            {customerInformation.firstName} {customerInformation.lastName}
          </span>
        </InfoSection>
      </Wrapper>
    </Container>
  );
}

export default BillingInformation;

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const Wrapper = styled('div', {
  '@mediaMinLarge': {
    display: 'flex',
    gap: '32px',
    mt: 8,
  },
});

const InfoSection = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  fs: 5,
  lineHeight: '16px',
  mt: 6,
  rowGap: '4px',
  '@mediaMinLarge': {
    fs: 7,
    mt: 0,
    lineHeight: '24px',
    w: '100%',
  },
});

const Bold = styled('span', {
  fontWeight: '$fw700',
});
